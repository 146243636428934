import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Credits from "../Credits";
import { ContactForm } from "../ContactForm";

import contactImg from "../../assets/contact/contactform.png";

const Contact = () => {
  return (
    <>
      <header>
        <Nav />
      </header>
      <main>
        <div className="contactBody">
          <div className="container wantToKnow">
            <h2>Do you want to know more about VIZPLAY?</h2>
            <p>
              Sign up for our newsletter to access news, developer details and
              to follow our journey
            </p>
          </div>
          <section className="formSection container">
            <div className="imgContainer">
              <img src={contactImg} alt="a white Vizplay camera" />
            </div>
            <div className="formContainer">
              <ContactForm />
            </div>
          </section>
        </div>
      </main>
      <footer>
        <Footer />
        <Credits />
      </footer>
    </>
  );
};

export default Contact;
