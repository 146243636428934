import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Credits from "../Credits";
import Faq from "../Faq";

import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";

//images
import schemBackgroundImg from "../../assets/schematics/background.png";
import vp01 from "../../assets/schematics/vp01.jpg";
import vp02 from "../../assets/schematics/vp02.jpg";
import howToUse from "../../assets/schematics/howusevizplay.png";
import tabletop from "../../assets/schematics/tabletop.png";
import projector from "../../assets/schematics/prjectericon.png";
import enjoy from "../../assets/schematics/enjoyicon.png";
import cardIcon01 from "../../assets/schematics/cardicon01.png";
import cardIcon02 from "../../assets/schematics/cardicon02.png";
import cardIcon03 from "../../assets/schematics/cardicon03.png";
import cardIcon04 from "../../assets/schematics/cardicon04.png";
import cardIcon05 from "../../assets/schematics/cardicon05.png";
import cardIcon06 from "../../assets/schematics/cardicon06.png";

const Schematic = () => {
  return (
    <div>
      <header>
        <Nav />
      </header>
      <main>
        <section
          className="schematicBody"
          style={{ backgroundImage: `url(${schemBackgroundImg})` }}
        >
          <div className="schematicContainer container animate__animated animate__zoomIn">
            <div className="schematic">
              <div className="textContainer">
                <h2>The Schematic</h2>
                <ul className="SchematicUl">
                  <li>High resolution 720p optical camera</li>
                  <li>60 degree FOV</li>
                  <li>
                    1080p video projection with proprietary adjustable lens
                  </li>
                </ul>
              </div>
              <div className="imgContainer">
                <img src={vp01} alt="vizplay camera front view" />
              </div>
            </div>
            <div className="schematic">
              <div className="textContainer">
                <ul className="SchematicUl">
                  <li>Ergonomic case with improved cooling</li>
                  <li>USB, ethernet, Wifi connectivity</li>
                  <li>Digital Audio connectivity</li>
                  <li>Proprietary telescopic design</li>
                </ul>
              </div>
              <div className="imgContainer changeOrder">
                <img src={vp02} alt="vizplay camera top view" />
              </div>
            </div>
          </div>
        </section>

        <section className="howToPlaySection">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <div className="howToPlayContainer container">
              <div className="imgContainer">
                <img src={howToUse} alt="a of bunch of players using Vizplay" />
              </div>
              <div className="howToPlayTextContainer">
                <h3 className="schematicH3">How to use Vizplay</h3>
                <div className="instructions">
                  <div className="imgContainer">
                    <img src={tabletop} alt="tabletop" />
                  </div>
                  <div className="howToPlay">
                    <h5>Find a tabletop</h5>
                    <p>
                      Attach the device to your gaming table with the included
                      clamp.
                    </p>
                  </div>
                </div>
                <div className="instructions">
                  <div className="imgContainer">
                    <img src={projector} alt="projector" />
                  </div>
                  <div className="howToPlay">
                    <h5>projector Setup the projector</h5>
                    <p>
                      Configure your projector how you like! Your tabletop, the
                      wall, a piece of paper. Even other boardgames become your
                      digital play surface!
                    </p>
                  </div>
                </div>
                <div className="instructions">
                  <div className="imgContainer">
                    <img src={enjoy} alt="enjoy icon" />
                  </div>
                  <div className="howToPlay">
                    <h5>Power on and enjoy!</h5>
                    <p>
                      Select from an increasing library of action, adventure,
                      strategy, puzzle and boardgames.
                    </p>
                  </div>
                </div>
                <h5>
                  Experience games only possible with Vizplay! Tabletop gaming
                  reimagined.
                </h5>
              </div>
            </div>
          </AnimationOnScroll>
        </section>

        <section className="keyFeaturesSection">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <div className="container">
              <h3 className="schematicH3">Key Features</h3>
              <p>
                Your Vizplay device contains a number of exciting features to
                enhance your gaming experience.
              </p>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <div className="cardContainer container">
              <div className="card">
                <div className="imgContainer">
                  <img src={cardIcon01} alt="projector" />
                </div>
                <h4>Tabletop Projector</h4>
                <p>
                  No headsets required! Your Vizplay projector delivers exciting
                  interactive graphics straight to your table.
                </p>
              </div>
              <div className="card">
                <div className="imgContainer">
                  <img src={cardIcon02} alt="mobile icon" />
                </div>
                <h4>Mobile App Support</h4>
                <p>
                  The companion app (iOS and Android) enables you to customize
                  your game controls, settings, access voice controls and more.
                </p>
              </div>
              <div className="card">
                <div className="imgContainer">
                  <img src={cardIcon03} alt="multiplayer icon" />
                </div>
                <h4>Multiple Players</h4>
                <p>
                  Play local multiplayer around the same table. No time to get
                  together? No problem! Enjoy internet-enabled multiplayer with
                  other Vizplay users around the world!
                </p>
              </div>
              <div className="card">
                <div className="imgContainer">
                  <img src={cardIcon04} alt="gesture icon" />
                </div>
                <h4>Gesture Control</h4>
                <p>
                  Intuitive controls! Interact with your game by touch and
                  gesture control, no clumsy hardware, wand or devices required
                  to play!
                </p>
              </div>
              <div className="card">
                <div className="imgContainer">
                  <img src={cardIcon05} alt="Motion Detection icon" />
                </div>
                <h4>Motion Detection</h4>
                <p>
                  Using your hand, a physical piece or object to enjoy immersive
                  gameplay, possible only from our high precision tracking
                  technology!
                </p>
              </div>
              <div className="card">
                <div className="imgContainer">
                  <img src={cardIcon06} alt="settings icon" />
                </div>
                <h4>Developer Friendly</h4>
                <p>
                  Have a game that would work well on Vizplay? Apply for access
                  to the developer SDK, port your game to the platform and host
                  it on our digital marketplace.{" "}
                  <Link to={`/Contact`}>Contact us</Link> for more info.
                </p>
              </div>
            </div>
          </AnimationOnScroll>
        </section>
        <section className="faqContainer">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <Faq />
            <div className="container">
              <Link to={`/Contact`} className="faqLink">
                Sign up for our Newsletter and more information
              </Link>
            </div>
          </AnimationOnScroll>
        </section>
      </main>
      <footer>
        <Footer />
        <Credits />
      </footer>
    </div>
  );
};

export default Schematic;
