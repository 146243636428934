import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Credits from "../Credits";
import { Link } from "react-router-dom";

// images
import vizplay from "../../assets/wordmark vizplay.png";
import background from "../../assets/Vizplay_bgpng-01.png";
import growingLib from "../../assets/home/growing-lib.png";
import memories from "../../assets/home/memories.png";
import tableTop from "../../assets/home/tabletop.png";

import lamp from "../../assets/home/lamp.png";
import games from "../../assets/home/games.png";
import programming from "../../assets/home/programming.png";
import videos from "../../assets/home/videos.png";

import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Homepage = () => {
  return (
    <>
      <header>
        <Nav />
      </header>
      <main>
        <section
          className="homeBody"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="container heroSection animate__animated animate__zoomIn">
            <div className="headingContainer">
              <h2>AR</h2>
              <h2>MULTIPLAYER</h2>
              <h2>MOTION CONTROL</h2>
            </div>

            <div className="imgContainer">
              <img src={vizplay} alt="vizplay" />
              <h3>Tabletop Gaming Reimagined</h3>
            </div>
          </div>
        </section>

        <section className="whatIsVizplay">
          <div className="container vizContainer">
            <h2>What's Vizplay?</h2>
            <p>
              Vizplay is a projection mapping device. Augment your tabletop
              games with digital interactive graphics you can touch. No headset
              required!
            </p>
          </div>

          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <div className="cardContainer container">
              <div className="card">
                <div className="imgContainer">
                  <img
                    src={tableTop}
                    alt="a table top with 2 pawn chess pieces"
                  />
                </div>

                <h4>Tabletop Augmented Reality</h4>
                <p>
                  Immersive interactive projected graphics on your tabletop, no
                  headset needed! Just clamp and go.
                </p>
              </div>
              <div className="card">
                <div className="imgContainer">
                  <img
                    src={growingLib}
                    alt="the ghost from Pacman and a dice"
                  />
                </div>

                <h4>Growing Library of Titles</h4>
                <p>
                  Select from a growing library of arcade, strategy, action,
                  puzzle, tabletop, and other genres only possible on Vizplay.
                </p>
              </div>
              <div className="card">
                <div className="imgContainer">
                  <img src={memories} alt="a party popper" />
                </div>
                <h4>Innovative Social Gaming</h4>
                <p>
                  Connect with your friends around a shared digital tabletop,
                  either in the same room or remotely over the internet.
                </p>
              </div>
            </div>
          </AnimationOnScroll>
        </section>

        <section className="about">
          <div className="container">
            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce
              initiallyVisible
            >
              <div className="aboutContainer">
                <div className="aboutTextContainer">
                  <h3>The Schematic</h3>
                  <p>
                    Our proprietary technology combines computer vision,
                    projection mapping and interactive gameplay in a consumer
                    product that is easy to install so you can quickly get in
                    the game.
                  </p>
                  <Link to={`/Schematic`} className="articleLink">
                    Find out More
                  </Link>
                </div>
                <div className="imgContainer">
                  <img src={lamp} alt="standard Vizplay camera" />
                </div>
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce
              initiallyVisible
            >
              <div className="aboutContainer">
                <div className="aboutTextContainer">
                  <h3>Games</h3>
                  <p>
                    Combining the best elements of tabletop gaming and
                    interactive graphics, a growing library of innovative games
                    is available in our digital marketplace.
                  </p>
                  <Link to={`/Games`} className="articleLink">
                    Explore All
                  </Link>
                </div>
                <div className="imgContainer changeOrder">
                  <img src={games} alt="a white Vizplay camera" />
                </div>
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce
              initiallyVisible
            >
              <div className="aboutContainer">
                <div className="aboutTextContainer">
                  <h3>Developers</h3>
                  <p>
                    We are open for business. Game developers can use our SDK to
                    develop games for our platform, using a variety of game
                    engines and languages.{" "}
                    <Link to={`/Contact`}>Contact us</Link> for more information
                  </p>
                  <Link to={`/Developers`} className="articleLink">
                    Build with Vizplay
                  </Link>
                </div>
                <div className="imgContainer">
                  <img src={programming} alt="a green Vizplay camera" />
                </div>
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce
              initiallyVisible
            >
              <div className="aboutContainer">
                <div className="aboutTextContainer">
                  <h3>Videos</h3>
                  <p>
                    Our journey is about to begin, we'll have some videos soon
                    enough!! <Link to={`/Contact`}>Contact us</Link> and we'll
                    keep you updated with news about Vizplay!
                  </p>
                  <Link to={`/Schematic`} className="articleLink">
                    Contact Us
                  </Link>
                </div>
                <div className="imgContainer changeOrder">
                  <img src={videos} alt="a queue of videos" />
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </section>

        <section className="commentsSection">
          <div className="commentHeadingContainer container">
            <h3>What other players are saying</h3>
            <p>
              Some quotes and testimonials from users who share our excitement
              for Vizplay!
            </p>
          </div>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <div className="commentContainer container">
              <div className="comment">
                <div className="starContainer">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <p>“This is so awesome!”</p>
                <div className="personContainer">
                  <h2>Manny Vega</h2>
                  <p>GAME DESIGNER</p>
                </div>
              </div>
              <div className="comment">
                <div className="starContainer">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <p>“This is the future of AR. No headset needed”</p>
                <div className="personContainer">
                  <h2>Floyd Miles</h2>
                  <p>VR/AR GAMER</p>
                </div>
              </div>
              <div className="comment">
                <div className="starContainer">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <p>“Playing my actual board games over the internet is huge”</p>
                <div className="personContainer">
                  <h2>Jane Cooper</h2>
                  <p>BOARD GAME ENTHUSIAST</p>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </section>
      </main>
      <footer>
        <Footer />
        <Credits />
      </footer>
    </>
  );
};

export default Homepage;
