export const gamesData = [
  {
    Name: "Dice Tracker",
    Genre: "Puzzle",
    Description: "A Single Player leisure Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2FDice-tracker-Title-Screen.png?alt=media&token=0848ad50-2df2-431d-9ce5-17afb5e6d237",
    TopRated: "False",
  },
  {
    Name: "Rock Paper Scissors",
    Genre: "Strategy",
    Description: "2 - 8 Players Strategy Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2FRPS.png?alt=media&token=502f6ce7-e998-40c3-9e78-d31eed6f811d",
    TopRated: "False",
  },
  {
    Name: "8-Bit Blaster",
    Genre: "Action",
    Description: "Single Player Action Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2F8-Bit-Blaster.png?alt=media&token=6490af6e-5502-444f-b445-cf37ca172f87",
    TopRated: "False",
  },
  {
    Name: "Bubble Shooter",
    Genre: "Action",
    Description: "Single Player Action Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2FBubbleShooter.png?alt=media&token=bcb27e2b-0cba-4d1f-8775-12c0f5a28639",
    TopRated: "True",
  },
  {
    Name: "Hidden Objects",
    Genre: "Puzzle",
    Description: "Single Player Puzzle Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2FHidden-Objects-title-Screen.png?alt=media&token=e703b35c-214b-4e4e-a095-22daa50e1a5d",
    TopRated: "True",
  },
  {
    Name: "Garfunkle Magic Blocks",
    Genre: "Puzzle",
    Description: "Single Player Memory Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2FMagic-Blocks-Title-Screen.png?alt=media&token=5aaebe05-207b-45d7-9383-950b3e3c7b01",
    TopRated: "False",
  },
  {
    Name: "Ablaze Balls",
    Genre: "Action",
    Description: "Single Player Action Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2FAblaze-Balls-Title-Screen.png?alt=media&token=669623b4-a5c9-4daa-ac05-2551863c9d20",
    TopRated: "True",
  },
  {
    Name: "Air Hockey",
    Genre: "Sports",
    Description: "1-2 Player Sports Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2FAir-Hockey-Title-Screen.png?alt=media&token=55c20e77-271b-4a1d-af08-991d23cf2126",
    TopRated: "True",
  },
  {
    Name: "Doodle Pad",
    Genre: "Action",
    Description: "A Single Player Game",
    Src: "https://firebasestorage.googleapis.com/v0/b/vizplay-85cab.appspot.com/o/Games-title-screens%2FDoodle-Pad.png?alt=media&token=f89b0780-0da5-4959-8ead-6e6ecde153c6",
    TopRated: "False",
  },
];
