import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export const ContactForm = () => {
  const form = useRef();

  const [showResult, setShowResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        `${process.env.REACT_APP_EMAIL_SERVICE_ID}`,
        `${process.env.REACT_APP_EMAIL_TEMPLATE_ID}`,
        form.current,
        `${process.env.REACT_APP_EMAIL_PUBLIC_KEY}`
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });

    e.target.reset();
    setShowResult(true);

    setTimeout(() => {
      setShowResult(false);
    }, 5000);
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="contactUsForm">
      <label htmlFor="user_name" className="sr-only">
        Name
      </label>
      <input
        type="text"
        name="user_name"
        required
        className="contactInput"
        placeholder="Your Name"
      />

      <label className="sr-only">Email</label>
      <input
        type="email"
        name="user_email"
        required
        className="contactInput"
        placeholder="Your Email"
      />

      <label className="sr-only">Message</label>
      <textarea name="message" required placeholder="Message" />

      <input type="submit" value="Send" className="submitBtn" />

      <div>
        {showResult ? (
          <p className="resultBody">Your message has been successfully sent.</p>
        ) : (
          <p className="hideBody">{""}</p>
        )}
      </div>
    </form>
  );
};
