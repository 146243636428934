import firebase from "../Firebase";
import { getDatabase, ref, onValue } from "firebase/database";
import { useEffect, useState } from "react";

import Nav from "../Nav";
import Footer from "../Footer";
import Credits from "../Credits";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const database = getDatabase(firebase);
    const dbRef = ref(database, `/Blogs`);

    onValue(dbRef, (response) => {
      const dbData = response.val();
      const dbBlogs = [];

      for (const key in dbData) {
        dbBlogs.push({ key: key, data: dbData[key] });
      }

      setBlogPosts(dbBlogs);
      console.log(blogPosts[0].data.blogTitle);
    });
  }, []);

  return (
    <>
      <header>
        <Nav />
      </header>
      <main className="contactBody">
        <section>
          <div className="container animate__animated animate__zoomIn">
            <h2 className="schematicH3 schematicH2">Blogs</h2>

            <section className="blogSection">
              {blogPosts.map((item) => {
                return (
                  <div key={item.key} className="blogContainer">
                    <h3 className="schematicH3">{item.data.blogTitle}</h3>
                    <p>{item.data.blogContent}</p>
                  </div>
                );
              })}
            </section>
          </div>
        </section>
      </main>
      <footer>
        <Footer />
        <Credits />
      </footer>
    </>
  );
};

export default Blog;
