import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Collapse = ({ title, content }) => {
  const [boxStatus, setBoxStatus] = useState(false);

  return (
    <div className="collapse">
      <button
        onClick={() => {
          setBoxStatus(!boxStatus);
        }}
      >
        <h4>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={boxStatus === false ? "pointDown" : "pointUp"}
          />
          {title}
        </h4>
      </button>

      <p
        style={
          boxStatus === false
            ? { height: 0, minHeight: "0" }
            : { height: "auto", minHeight: "100px" }
        }
        className="content"
      >
        {content}
      </p>
    </div>
  );
};

export default Collapse;
