import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Credits from "../Credits";
import Faq from "../Faq";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";

// images
import devBackground from "../../assets/developer/background.png";
import unityEngine from "../../assets/developer/unityengine.png";
import unity from "../../assets/developer/tools/tool1.png";
import cSharp from "../../assets/developer/tools/tool2.png";
import unreal from "../../assets/developer/tools/tool3.png";
import twitch from "../../assets/developer/tools/tool4.png";

const Developers = () => {
  return (
    <>
      <header>
        <Nav />
      </header>
      <main>
        <section
          className="developerBody"
          id="developers"
          style={{ backgroundImage: `url(${devBackground})` }}
        >
          <div className="container developerTitleContainer  animate__animated animate__zoomIn">
            <h2>Become a Vizplay Developer</h2>
            <p>
              Bring your game to the best digital tabletop platform with our
              easy to use SDK.
            </p>
          </div>
        </section>

        <section className="buildGamesSection">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <div className="container buildGamesContainer">
              <div className="buildGames">
                <h3>Build your games on Vizplay</h3>
                <p>
                  Have a game that would work well on Vizplay? Apply for access
                  to the developer SDK, port your game to the platform and host
                  it on our digital marketplace.
                </p>
                <Link to={`/Contact`} className="articleLink">
                  Join us now!
                </Link>
              </div>
              <div className="imgContainer">
                <img src={unityEngine} alt="unity engin code editor" />
              </div>
            </div>
          </AnimationOnScroll>
        </section>
        <section className="faqContainer">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <Faq />
          </AnimationOnScroll>
        </section>
        <section className="tools">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
            animateOnce
            initiallyVisible
          >
            <div className="container">
              <p>Tools we use</p>
              <div className="toolsContainer">
                <div className="imgContainer">
                  <img src={unity} alt="unity engine" />
                </div>
                <div className="imgContainer">
                  <img src={cSharp} alt="c#" />
                </div>
                <div className="imgContainer">
                  <img src={unreal} alt="unreal engine" />
                </div>
                <div className="imgContainer">
                  <img src={twitch} alt="twitch" />
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </section>
      </main>
      <footer>
        <Footer />
        <Credits />
      </footer>
    </>
  );
};

export default Developers;
