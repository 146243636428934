import React from "react";
import Collapse from "./Collapse";

const Faq = () => {
  return (
    <section className="faqSection">
      <div className="container">
        <div className="headingContainer">
          <h2>FAQ</h2>
          <p>
            Check below for answers to some common questions about Vizplay!.
          </p>
        </div>
      </div>

      <div className="container">
        <Collapse
          title={`What is Vizplay? `}
          content={
            "Vizplay is a projection mapping device for the home. Augment your tabletop games with digital interactive graphics you can touch. No headset required! "
          }
        />
        <Collapse
          title={`What is the ideal environment for Vizplay?`}
          content={
            "Dim the lights until the projection suits your play environment.Consistent lighting during gameplay is ideal. "
          }
        />
      </div>
    </section>
  );
};

export default Faq;
