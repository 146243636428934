import "./styles/App.css";

import { Route, Routes } from "react-router-dom";
import Homepage from "./Components/pages/Homepage";
import Schematic from "./Components/pages/Schematic";
import Developers from "./Components/pages/Developers";
import Games from "./Components/pages/Games";
import Legal from "./Components/pages/Legal";
import Contact from "./Components/pages/Contact";
import Blog from "./Components/pages/Blog";
import BlogAdmin from "./Components/pages/BlogAdmin";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Schematic" element={<Schematic />} />
        <Route path="/Developers" element={<Developers />} />
        <Route path="/Games" element={<Games />} />
        <Route path="/Legal" element={<Legal />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Posts" element={<BlogAdmin />} />
      </Routes>
    </div>
  );
}

export default App;
