import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Subscribe from "./Subscribe";

const Footer = () => {
  return (
    <section id="footer" className="footerContainer">
      <div className="container footer">
        <div className="socialDiv">
          <a href="/">
            <img src={logo} alt="Vizplay logo" />
          </a>
          <p>Tabletop Gaming Reimagined</p>
          <ul className="socialContainer">
            <li>
              <a href="https://www.facebook.com/VizplayGame">
                <FontAwesomeIcon icon={faFacebook} className="social" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/VizPlay">
                <FontAwesomeIcon icon={faTwitter} className="social" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCJy7vYoXqv8PP7nfjSW4ApA">
                <FontAwesomeIcon icon={faYoutube} className="social" />
              </a>
            </li>
          </ul>
        </div>

        <div className="siteMapDiv">
          <h4>Sitemap</h4>
          <ul>
            <li>
              <Link to={`/`} smooth>
                Home
              </Link>
            </li>
            <li>
              <Link to={`/Schematic`} smooth>
                Schematic
              </Link>
            </li>
            <li>
              <Link to={`/Games#gamePage`} smooth>
                Games
              </Link>
            </li>
            <li>
              <Link to={`/Developers#developers`} smooth>
                Developers
              </Link>
            </li>
            <li>
              <Link to={`/Contact`} smooth>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className="newsLetterDiv">
          <h3>Subscribe to our Newsletter</h3>
          <Subscribe />
        </div>
      </div>
    </section>
  );
};

export default Footer;
