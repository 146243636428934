import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Credits from "../Credits";

const Legal = () => {
  return (
    <div>
      <header>
        <Nav />
      </header>
      <main className="legalBody" id="Terms">
        <div className="container">
          <h2>Legal & Credit</h2>
          <div className="legalBox">
            <h3 className="legalHeading">Terms and Conditions</h3>
            <p>
              These terms and conditions ("Agreement") set forth the general
              terms and conditions of your use of the Vizplay.net website
              ("Website" or "Service") and any of its related products and
              services (collectively, "Services"). This Agreement is legally
              binding between you ("User", "you" or "your") and
              Vizplay("Vizplay", "we", "us" or "our"). By accessing and using
              the Website and Services, you acknowledge that you have read,
              understood, and agree to be bound by the terms of this Agreement.
              If you are entering into this Agreement on behalf of a business or
              other legal entity, you represent that you have the authority to
              bind such entity to this Agreement, in which case the terms
              "User", "you" or "your" shall refer to such entity. If you do not
              have such authority, or if you do not agree with the terms of this
              Agreement, you must not accept this Agreement and may not access
              and use the Website and Services. You acknowledge that this
              Agreement is a contract between you and Vizplay, even though it is
              electronic and is not physically signed by you, and it governs
              your use of the Website and Services.
            </p>

            <p>Accounts and membership</p>

            <p>
              If you create an account on the Website, you are responsible for
              maintaining the security of your account and you are fully
              responsible for all activities that occur under the account and
              any other actions taken in connection with it. We may monitor and
              review new accounts before you may sign in and start using the
              Services. Providing false contact information of any kind may
              result in the termination of your account. You must immediately
              notify us of any unauthorized uses of your account or any other
              breaches of security. We will not be liable for any acts or
              omissions by you, including any damages of any kind incurred as a
              result of such acts or omissions. We may suspend, disable, or
              delete your account (or any part thereof) if we determine that you
              have violated any provision of this Agreement or that your conduct
              or content would tend to damage our reputation and goodwill. If we
              delete your account for the foregoing reasons, you may not
              re-register for our Services. We may block your email address and
              Internet protocol address to prevent further registration.
            </p>

            <p>Billing and payments</p>
            <p>
              You shall pay all fees or charges to your account in accordance
              with the fees, charges, and billing terms in effect at the time a
              fee or charge is due and payable. If auto-renewal is enabled for
              the Services you have subscribed for, you will be charged
              automatically in accordance with the term you selected. If, in our
              judgment, your purchase constitutes a high-risk transaction, we
              will require you to provide us with a copy of your valid
              government-issued photo identification, and possibly a copy of a
              recent bank statement for the credit or debit card used for the
              purchase. We reserve the right to change products and product
              pricing at any time. We also reserve the right to refuse any order
              you place with us. We may, in our sole discretion, limit or cancel
              quantities purchased per person, per household or per order. These
              restrictions may include orders placed by or under the same
              customer account, the same credit card, and/or orders that use the
              same billing and/or shipping address. In the event that we make a
              change to or cancel an order, we may attempt to notify you by
              contacting the e-mail and/or billing address/phone number provided
              at the time the order was made.
            </p>

            <p>Accuracy of information</p>

            <p>
              Occasionally there may be information on the Website that contains
              typographical errors, inaccuracies or omissions that may relate to
              product descriptions, pricing, availability, promotions and
              offers. We reserve the right to correct any errors, inaccuracies
              or omissions, and to change or update information or cancel orders
              if any information on the Website or Services is inaccurate at any
              time without prior notice (including after you have submitted your
              order). We undertake no obligation to update, amend or clarify
              information on the Website including, without limitation, pricing
              information, except as required by law. No specified update or
              refresh date applied on the Website should be taken to indicate
              that all information on the Website or Services has been modified
              or updated.
            </p>

            <p>Advertisements</p>

            <p>
              During your use of the Website and Services, you may enter into
              correspondence with or participate in promotions of advertisers or
              sponsors showing their goods or services through the Website and
              Services. Any such activity, and any terms, conditions, warranties
              or representations associated with such activity, is solely
              between you and the applicable third party. We shall have no
              liability, obligation or responsibility for any such
              correspondence, purchase or promotion between you and any such
              third party.
            </p>

            <p>Links to other resources</p>

            <p>
              Although the Website and Services may link to other resources
              (such as websites, mobile applications, etc.), we are not,
              directly or indirectly, implying any approval, association,
              sponsorship, endorsement, or affiliation with any linked resource,
              unless specifically stated herein. Some of the links on the
              Website may be "affiliate links". This means if you click on the
              link and purchase an item, Vizplay will receive an affiliate
              commission. We are not responsible for examining or evaluating,
              and we do not warrant the offerings of, any businesses or
              individuals or the content of their resources. We do not assume
              any responsibility or liability for the actions, products,
              services, and content of any other third parties. You should
              carefully review the legal statements and other conditions of use
              of any resource which you access through a link on the Website and
              Services. Your linking to any other off-site resources is at your
              own risk.
            </p>

            <p>Prohibited uses</p>

            <p>
              In addition to other terms as set forth in the Agreement, you are
              prohibited from using the Website and Services or Content: (a) for
              any unlawful purpose; (b) to solicit others to perform or
              participate in any unlawful acts; (c) to violate any
              international, federal, provincial or state regulations, rules,
              laws, or local ordinances; (d) to infringe upon or violate our
              intellectual property rights or the intellectual property rights
              of others; (e) to harass, abuse, insult, harm, defame, slander,
              disparage, intimidate, or discriminate based on gender, sexual
              orientation, religion, ethnicity, race, age, national origin, or
              disability; (f) to submit false or misleading information; (g) to
              upload or transmit viruses or any other type of malicious code
              that will or may be used in any way that will affect the
              functionality or operation of the Website and Services, third
              party products and services, or the Internet; (h) to spam, phish,
              pharm, pretext, spider, crawl, or scrape; (i) for any obscene or
              immoral purpose; or (j) to interfere with or circumvent the
              security features of the Website and Services, third party
              products and services, or the Internet. We reserve the right to
              terminate your use of the Website and Services for violating any
              of the prohibited uses.
            </p>

            <p>Intellectual property rights</p>

            <p>
              "Intellectual Property Rights" means all present and future rights
              conferred by statute, common law or equity in or in relation to
              any copyright and related rights, trademarks, designs, patents,
              inventions, goodwill and the right to sue for passing off, rights
              to inventions, rights to use, and all other intellectual property
              rights, in each case whether registered or unregistered and
              including all applications and rights to apply for and be granted,
              rights to claim priority from, such rights and all similar or
              equivalent rights or forms of protection and any other results of
              intellectual activity which subsist or will subsist now or in the
              future in any part of the world. This Agreement does not transfer
              to you any intellectual property owned by Vizplay or third
              parties, and all rights, titles, and interests in and to such
              property will remain (as between the parties) solely with Vizplay.
              All trademarks, service marks, graphics and logos used in
              connection with the Website and Services, are trademarks or
              registered trademarks of Vizplay or its licensors. Other
              trademarks, service marks, graphics and logos used in connection
              with the Website and Services may be the trademarks of other third
              parties. Your use of the Website and Services grants you no right
              or license to reproduce or otherwise use any of Vizplay or third
              party trademarks.
            </p>

            <p>Disclaimer of warranty</p>

            <p>
              You agree that such Service is provided on an "as is" and "as
              available" basis and that your use of the Website and Services is
              solely at your own risk. We expressly disclaim all warranties of
              any kind, whether express or implied, including but not limited to
              the implied warranties of merchantability, fitness for a
              particular purpose and non-infringement. We make no warranty that
              the Services will meet your requirements, or that the Service will
              be uninterrupted, timely, secure, or error-free; nor do we make
              any warranty as to the results that may be obtained from the use
              of the Service or as to the accuracy or reliability of any
              information obtained through the Service or that defects in the
              Service will be corrected. You understand and agree that any
              material and/or data downloaded or otherwise obtained through the
              use of Service is done at your own discretion and risk and that
              you will be solely responsible for any damage or loss of data that
              results from the download of such material and/or data. We make no
              warranty regarding any goods or services purchased or obtained
              through the Service or any transactions entered into through the
              Service unless stated otherwise. No advice or information, whether
              oral or written, obtained by you from us or through the Service
              shall create any warranty not expressly made herein.
            </p>

            <p>Limitation of liability</p>

            <p>
              To the fullest extent permitted by applicable law, in no event
              will Vizplay, its affiliates, directors, officers, employees,
              agents, suppliers or licensors be liable to any person for any
              indirect, incidental, special, punitive, cover or consequential
              damages (including, without limitation, damages for lost profits,
              revenue, sales, goodwill, use of content, impact on business,
              business interruption, loss of anticipated savings, loss of
              business opportunity) however caused, under any theory of
              liability, including, without limitation, contract, tort,
              warranty, breach of statutory duty, negligence or otherwise, even
              if the liable party has been advised as to the possibility of such
              damages or could have foreseen such damages. To the maximum extent
              permitted by applicable law, the aggregate liability of Vizplay
              and its affiliates, officers, employees, agents, suppliers and
              licensors relating to the services will be limited to an amount
              greater of one dollar or any amounts actually paid in cash by you
              to Vizplay for the prior one month period prior to the first event
              or occurrence giving rise to such liability. The limitations and
              exclusions also apply if this remedy does not fully compensate you
              for any losses or fails of its essential purpose.
            </p>

            <p>Indemnification</p>

            <p>
              You agree to indemnify and hold Vizplay and its affiliates,
              directors, officers, employees, agents, suppliers and licensors
              harmless from and against any liabilities, losses, damages or
              costs, including reasonable attorneys' fees, incurred in
              connection with or arising from any third party allegations,
              claims, actions, disputes, or demands asserted against any of them
              as a result of or relating to your Content, your use of the
              Website and Services or any willful misconduct on your part.
            </p>

            <p>Severability</p>

            <p>
              All rights and restrictions contained in this Agreement may be
              exercised and shall be applicable and binding only to the extent
              that they do not violate any applicable laws and are intended to
              be limited to the extent necessary so that they will not render
              this Agreement illegal, invalid or unenforceable. If any provision
              or portion of any provision of this Agreement shall be held to be
              illegal, invalid or unenforceable by a court of competent
              jurisdiction, it is the intention of the parties that the
              remaining provisions or portions thereof shall constitute their
              agreement with respect to the subject matter hereof, and all such
              remaining provisions or portions thereof shall remain in full
              force and effect.
            </p>

            <p>Dispute resolution</p>

            <p>
              The formation, interpretation, and performance of this Agreement
              and any disputes arising out of it shall be governed by the
              substantive and procedural laws of Texas, United States without
              regard to its rules on conflicts or choice of law and, to the
              extent applicable, the laws of United States. The exclusive
              jurisdiction and venue for actions related to the subject matter
              hereof shall be the courts located in Texas, United States, and
              you hereby submit to the personal jurisdiction of such courts. You
              hereby waive any right to a jury trial in any proceeding arising
              out of or related to this Agreement. The United Nations Convention
              on Contracts for the International Sale of Goods does not apply to
              this Agreement.
            </p>

            <p>Assignment</p>

            <p>
              You may not assign, resell, sub-license or otherwise transfer or
              delegate any of your rights or obligations hereunder, in whole or
              in part, without our prior written consent, which consent shall be
              at our own sole discretion and without obligation; any such
              assignment or transfer shall be null and void. We are free to
              assign any of its rights or obligations hereunder, in whole or in
              part, to any third party as part of the sale of all or
              substantially all of its assets or stock or as part of a merger.
            </p>

            <p>Changes and amendments</p>

            <p>
              We reserve the right to modify this Agreement or its terms
              relating to the Website and Services at any time, effective upon
              posting of an updated version of this Agreement on the Website.
              When we do, we will revise the updated date at the bottom of this
              page. Continued use of the Website and Services after any such
              changes shall constitute your consent to such changes.
            </p>

            <p>Acceptance of these terms</p>

            <p>
              You acknowledge that you have read this Agreement and agree to all
              its terms and conditions. By accessing and using the Website and
              Services you agree to be bound by this Agreement. If you do not
              agree to abide by the terms of this Agreement, you are not
              authorized to access or use the Website and Services.
            </p>

            <p>Contacting us</p>

            <p>
              If you would like to contact us to understand more about this
              Agreement or wish to contact us concerning any matter relating to
              it, you may send an email to contact@getvizplay.net. This document
              was last updated on June 23, 2021
            </p>
          </div>

          <div id="Privacy" className="legalBox">
            <h3 className="legalHeading">Privacy Policy</h3>
            <p>
              This SERVICE is provided at no cost and is intended for use as is.
            </p>

            <p>
              This page is used to inform visitors regarding our policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use our Service.
            </p>
            <p>
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p>
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at test unless
              otherwise defined in this Privacy Policy.
            </p>

            <p>Information Collection and Use</p>

            <p>
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information, including but not limited to none. The information
              that we request will be retained by us and used as described in
              this privacy policy.
            </p>
            <p>
              The app does use third party services that may collect information
              used to identify you.
            </p>
            <p>
              Link to privacy policy of third party service providers used by
              the app
            </p>

            <p>Google Play Services</p>
            <p>Log Data</p>

            <p>
              We want to inform you that whenever you use our Service, in a case
              of an error in the app we collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              ("IP") address, device name, operating system version, the
              configuration of the app when utilizing our Service, the time and
              date of your use of the Service, and other statistics.
            </p>

            <p>Cookies</p>

            <p>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </p>
            <p>
              This Service does not use these "cookies" explicitly. However, the
              app may use third party code and libraries that use "cookies" to
              collect information and improve their services. You have the
              option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our
              cookies, you may not be able to use some portions of this Service.
            </p>

            <p>Service Providers</p>

            <p>
              We may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <p>To facilitate our Service;</p>
            <p>To provide the Service on our behalf;</p>
            <p>To perform Service-related services; or</p>
            <p>To assist us in analyzing how our Service is used.</p>
            <p>
              We want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>

            <p>Security</p>

            <p>
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </p>

            <p>Links to Other Sites</p>

            <p>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </p>

            <p>Children's Privacy</p>

            <p>
              These Services do not address anyone under the age of 13. We do
              not knowingly collect personally identifiable information from
              children under 13. In the case we discover that a child under 13
              has provided us with personal information, we immediately delete
              this from our servers. If you are a parent or guardian and you are
              aware that your child has provided us with personal information,
              please contact us so that we will be able to do necessary actions.
            </p>

            <p>Changes to This Privacy Policy</p>

            <p>
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page.
            </p>

            <p>This policy is effective as of 2020-07-30</p>

            <p>Contact Us</p>

            <p>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at test.
            </p>
          </div>

          <div id="Attribution" className="legalBox">
            <h3 className="legalHeading">Attribution and Credit</h3>
            <p>
              Java Programming Vector created by{" "}
              <a href="https://www.freepik.es/autor/fullvector">fullvector</a>
            </p>
            <p>
              Confetti Icons created by{" "}
              <a href="https://www.freepik.es">fullvector</a>
            </p>

            <h4>Art Design</h4>
            <a href="http://www.freepik.com/">BRGFX / Freepik</a>
            <a href="https://www.flaticon.com/">Freepik / Flaticon</a>
            <a href="https://www.flaticon.com/authors/dinosoftlabs">
              DinosoftLabs
            </a>
            <a href="https://www.vecteezy.com/free-vector/rock-paper-scissors">
              Vecteezy
            </a>
            <a href="https://www.flaticon.com/authors/vectors-market">
              Vectors Market / Flatiron
            </a>
            <a href="https://icons8.com/illustrations/author/ZD2OQC8LLBBA">
              ICONS 8
            </a>
            <a href="https://www.freepik.com/vectors/neon-wallpaper">
              PIKISUPERSTAR / Freepik
            </a>

            <h4>Sound Effects</h4>
            <a href="http://www.orangefreesounds.com/">Orange Free Sounds</a>
            <a href="https://www.zapsplat.com/">ZapSplat</a>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
        <Credits />
      </footer>
    </div>
  );
};

export default Legal;
