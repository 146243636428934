import React from "react";

const GameList = ({ gamesArray }) => {
  return (
    <ul className="gameListContainer">
      {gamesArray.map((game) => {
        return (
          <li key={game.Name}>
            <div className="imgContainer">
              <img src={game.Src} alt={`title screen of ${game.Name}`} />
            </div>
            <h3>{game.Name}</h3>
            <p>{game.Description}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default GameList;
