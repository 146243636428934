import React from "react";
import { useState } from "react";
import logo from "../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const Nav = () => {
  const [navStatus, setNavStatus] = useState(false);

  return (
    <div className="backgroundContainer">
      <div className="container navContainer">
        <h1 className="logoContainer">
          <a href="/">
            <img src={logo} alt="Vizplay logo" />
          </a>
        </h1>
        <nav>
          <ul className={navStatus ? "showMe" : "hideMe"}>
            <li>
              <Link to={`/`} className="navLink" smooth>
                Home
              </Link>
            </li>
            <li>
              <Link to={`/Schematic`} className="navLink" smooth>
                Schematic
              </Link>
            </li>
            <li>
              <Link to={`/Games#gamePage`} className="navLink" smooth>
                Games
              </Link>
            </li>
            <li>
              <Link to={`/Blog`} className="navLink" smooth>
                Blog
              </Link>
            </li>
            <li>
              <Link to={`/Developers#developers`} className="navLink" smooth>
                Developers
              </Link>
            </li>
            <li>
              <Link to={`/Contact`} className="ContactLink" smooth>
                Contact Us
              </Link>
            </li>
          </ul>
          <button
            className="mobileMenuBtn"
            aria-hidden="true"
            onClick={() => {
              setNavStatus(!navStatus);
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Nav;
