import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const credits = () => {
  return (
    <div className="creditsContainer">
      <div className="copyright">
        &copy; Copyright © 2022{" "}
        <strong>
          <span>Arbelos Interactive</span>
        </strong>
      </div>
      <div className="credits">
        <p> All Rights Reserved</p>
        <ul>
          <li>
            <Link to={`/Legal#Terms`} smooth>
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link to={`/Legal#Privacy`} smooth>
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to={`/Legal#Attribution`} smooth>
              Attribution
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default credits;
