import { useState } from "react";
import firebase from "../Firebase";
import { getDatabase, ref, push } from "firebase/database";

import Nav from "../Nav";
import Footer from "../Footer";
import Credits from "../Credits";

const BlogAdmin = () => {
  const [blogTitle, setBlogTitle] = useState("");
  const [blogContent, setBlogContent] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const database = getDatabase(firebase);
    const dbRef = ref(database, `/Blogs`);

    const currentBlog = {
      blogTitle: blogTitle,
      blogContent: blogContent,
    };
    push(dbRef, currentBlog);

    setBlogTitle("");
    setBlogContent("");
    event.target.reset();
  };

  return (
    <>
      <header>
        <Nav />
      </header>
      <main className="contactBody">
        <section>
          <div className="container animate__animated animate__zoomIn">
            <h2 className="schematicH3 schematicH2">Add new post</h2>

            <div className="blogFormContainer">
              <form
                action=""
                method="post"
                className="blogForm"
                onSubmit={handleSubmit}
              >
                <label htmlFor="blogHeading" className="sr-only">
                  blog Heading
                </label>
                <input
                  type="text"
                  name="blogHeading"
                  className="blogHeading"
                  placeholder="Title"
                  onChange={(e) => {
                    setBlogTitle(e.target.value);
                  }}
                />

                <label htmlFor="blogContent" className="sr-only">
                  blog Content
                </label>
                <textarea
                  name="blogContent"
                  className="blogContent"
                  id="blogContent"
                  placeholder="Content"
                  onChange={(e) => {
                    setBlogContent(e.target.value);
                  }}
                ></textarea>

                <button type="submit" className=" submitBtn blogBtn">
                  Post Blog
                </button>
              </form>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <Footer />
        <Credits />
      </footer>
    </>
  );
};

export default BlogAdmin;
