import React from "react";
import Nav from "../Nav";
import Footer from "../Footer";
import Credits from "../Credits";
import GameList from "../GameList";

import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import gamesVideo from "../../assets/games/compilation.mp4";

// data
import { gamesData } from "../../Data/GamesData";

const Games = () => {
  //#region states and functions
  const [selectedGenre, setSelectedGenre] = useState("All");

  const handleClick = (stateValue) => {
    setSelectedGenre(`${stateValue}`);
  };

  let topGames = gamesData.filter((game) => {
    return game.TopRated === "True";
  });

  let newGameData = [];

  if (selectedGenre === "All") {
    newGameData = gamesData;
  } else {
    newGameData = gamesData.filter((game) => {
      return game.Genre === selectedGenre;
    });
  }
  //#endregion

  return (
    <>
      <header>
        <Nav />
      </header>
      <main className="gamesMain" id="gamePage">
        <div className="container animate__animated animate__zoomIn">
          <video controls className="videoContainer">
            <source src={gamesVideo} type="video/mp4"></source>
          </video>
          <h2 className="gamesHeading">Top Rated Games</h2>
          <GameList gamesArray={topGames} />
        </div>
        <AnimationOnScroll
          animateIn="animate__fadeInUp"
          animateOnce
          initiallyVisible
        >
          <div className="container">
            <div className="displayGames">
              <div className="filterContainer">
                <h3>Games List</h3>
                <ul className="filterList">
                  <li>
                    <button
                      onClick={() => {
                        handleClick("All");
                      }}
                      className="filterBtn"
                    >
                      All
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        handleClick("Action");
                      }}
                      className="filterBtn"
                    >
                      Action
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        handleClick("Sports");
                      }}
                      className="filterBtn"
                    >
                      Sports
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        handleClick("Puzzle");
                      }}
                      className="filterBtn"
                    >
                      Puzzle
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        handleClick("Strategy");
                      }}
                      className="filterBtn"
                    >
                      Strategy
                    </button>
                  </li>
                </ul>
              </div>

              <GameList gamesArray={newGameData} />
            </div>
          </div>
        </AnimationOnScroll>
      </main>
      <footer>
        <Footer />
        <Credits />
      </footer>
    </>
  );
};

export default Games;
