import { useEffect, useState } from "react";
import firebase from "./Firebase";
import { getDatabase, ref, onValue, push } from "firebase/database";

const Subscribe = () => {
  const [userInput, setUserInput] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [subscriptionResult, setSubscriptionResult] = useState("");

  useEffect(() => {
    const database = getDatabase(firebase);
    const dbRef = ref(database, `/Email_Subscriptions`);

    onValue(dbRef, (response) => {
      const dbData = response.val();
      const dbSubscribers = [];

      for (const key in dbData) {
        dbSubscribers.push(dbData[key]);
      }

      setSubscribers(dbSubscribers);
    });
  }, []);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const database = getDatabase(firebase);
    const dbRef = ref(database, `/Email_Subscriptions`);

    if (!subscribers.includes(userInput)) {
      push(dbRef, userInput);
      setSubscriptionResult("You have been successfully subscribed.");
    } else {
      setSubscriptionResult("You are already a subscriber.");
    }

    setUserInput("");
    event.target.reset();
    setShowResult(true);

    setTimeout(() => {
      setShowResult(false);
    }, 5000);
  };

  return (
    <div className="subscribeContainer">
      <form action="" className="subscribeForm" onSubmit={handleSubmit}>
        <label htmlFor="subscribe" className="sr-only">
          Email subscription
        </label>
        <input
          type="email"
          name="subscribe"
          id="subscribe"
          onChange={handleInputChange}
        />
        <button type="submit">Subscribe</button>
      </form>
      <div>
        {showResult ? (
          <p className="resultBody">{subscriptionResult}</p>
        ) : (
          <p className="hideBody">{""}</p>
        )}
      </div>
    </div>
  );
};

export default Subscribe;
